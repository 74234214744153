body {
  margin: 0;
  padding: 0;
  font-family: "Roboto", -apple-system, BlinkMacSystemFont, "Segoe UI", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: url("./assets/what-the-hex.png");
  background-attachment: fixed;
}

@media only print {
  body {
    background: white;
    width: 8.5in;
    overflow: visible;
  }
  .paper {
    box-shadow: none !important;
  }
  .print-hide {
    display: none !important;
  }
  .small-print {
    height: 80px !important;
    width: 80px !important;
  }
  #website {
    display: list-item !important;
  }

  h4 {
    font-size: 1.725rem !important;
  }
  h5 {
    font-size: 1.125rem !important;
  }
}
p {
  font-size: 10pt;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
ul {
  list-style: none;
  margin-top: 5px;
  margin-bottom: 5px;
  padding-left: 15px;
}

ul li {
  font-size: 10pt;
  line-height: 20px;
}

li,
p,
h1,
h2,
h3,
h4,
h5,
h6 {
  page-break-inside: avoid;
  break-inside: avoid;
}

.react-json-view {
  position: absolute !important;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: auto;
}

#website {
  display: none;
}
